main + footer {
  margin-top: 2rem;
}
@media (min-width: 992px) {
main + footer {
    margin-top: 4rem;
}
}
footer {
  max-width: 100%;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  margin: 4rem 0;
  padding-bottom: 4rem;
}
footer hr {
  margin: 2rem 0;
}
@media (max-width: 767.98px) {
footer .container-fluid {
    padding: 0;
}
footer .container-fluid .col-sm-12:not(:last-child) {
    margin-bottom: 1rem;
}
}
@media (min-width: 992px) {
footer {
    margin-left: 30px;
    margin-right: 30px;
}
}
.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}
.vue-back-to-top {
  z-index: 1 !important;
}
.vue-back-to-top button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vue-back-to-top svg path {
  stroke: #fff;
}