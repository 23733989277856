.termsShortContainer .termTitle {
  text-transform: uppercase;
  display: block;
  clear: both;
  margin-bottom: 4px;
}
.termsShortContainer .termContent {
  display: block;
  margin-bottom: 1.5rem;
}
.termsShortContainer .termsShortModals {
  margin-bottom: 2rem;
}
.termsShortContainer .termsShortModals .termButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
}
.termsShortContainer .termsShortModals .termButton svg {
  width: 16px;
  height: auto;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.termsShortContainer .termsShortModals .termTitle {
  display: block;
  width: 100%;
  font-size: 12px;
  padding-left: 6px;
}
.termsShortContainer .termsShortExpanded {
  display: none;
}
@media (min-width: 576px) {
.termsShortContainer .termsShortModals {
    display: none;
}
.termsShortContainer .termsShortExpanded {
    display: block;
}
}
.termsModal {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.termsModal .termTitleModal {
  text-transform: uppercase;
  color: #0077CA;
  font-size: 16px;
}
.termsModal .termContent {
  font-size: 13px;
}