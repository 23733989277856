#sidebar .sidebar-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 110px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
}
@media (min-width: 1600px) {
#sidebar .sidebar-logo {
    padding-left: 60px;
}
}
#sidebar .sidebar-logo svg {
  max-width: 65%;
}
#sidebar .sidebar-logo .logo {
  cursor: pointer;
}
#sidebar .sidebar-logo-focus {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-size: 16px;
  padding-right: 20px;
}
#sidebar .sidebar-logo-focus svg {
  margin-left: 20px;
  min-width: 28px;
}
#sidebar .sidebar-logo-focus:after {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) translateX(50%);
          transform: translateY(-50%) translateX(50%);
  background: #f07b5d;
  border-radius: 50%;
}
@media (min-width: 992px) {
#sidebar .sidebar-content--boxset {
    position: sticky;
    top: 20px;
}
}
#sidebar .sidebarPlaceholder {
  min-height: 85px;
}
#sidebar .sidebarUnlockUpselling {
  padding-right: 30px;
  margin-top: 2rem;
}
#sidebar .sidebarUnlockUpselling svg * {
  fill: #fff;
}
#sidebar .sidebarUnlockUpselling p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0;
}
#sidebar .sidebarUnlockUpselling .danger {
  min-width: 100%;
  padding-top: 0.5rem;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: left;
  line-height: 120%;
}
#sidebar .sidebarUnlockUpselling .danger svg {
  margin-right: 8px;
  width: 16px;
  min-width: 16px;
}
#sidebar .sidebarUnlockUpselling .danger svg * {
  fill: #dc3545;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 1.75rem;
  padding-left: 1.5rem;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock:first-child, #sidebar .sidebarUnlockUpselling .upsellingBlock:last-child {
  padding-left: 0;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .icon svg {
  width: 18px;
  height: auto;
  margin-right: 8px;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .icon svg[data-icon=euro-sign] {
  width: 12px;
  margin-right: 7px;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .icon svg[data-icon=moon] {
  width: 15px;
  margin-right: 6px;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .text .textTitle {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.35rem;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .text strong {
  font-size: 1.15rem;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock .subBlock {
  font-size: 0.9rem;
}
#sidebar .sidebarUnlockUpselling .upsellingBlock input,
#sidebar .sidebarUnlockUpselling .upsellingBlock select {
  padding: 4px 10px;
  text-align: center;
  height: 30px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
#sidebar .sidebarUnlockUpselling .switchBox,
#sidebar .sidebarUnlockUpselling .nightsBox,
#sidebar .sidebarUnlockUpselling .nightlyPriceBox {
  margin-left: 2rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 100px;
  min-width: 100px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#sidebar .sidebarUnlockUpselling .switchBox *,
#sidebar .sidebarUnlockUpselling .nightsBox *,
#sidebar .sidebarUnlockUpselling .nightlyPriceBox * {
  font-size: 13px;
}
#sidebar .sidebarUnlockUpselling .nightsBox .input-with-icon {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
#sidebar .sidebarUnlockUpselling .nightlyPriceBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#sidebar .sidebarUnlockUpselling .nightlyPriceBox span {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 40%;
}
#sidebar .sidebarUnlockUpselling .nightlyPriceBox span:first-child {
  margin-right: 6px;
}
#sidebar .sidebarUnlockUpselling .nightlyPriceBox span:last-child {
  margin-left: 6px;
}
#sidebar .sidebarUnlockUpselling .searchBox {
  width: 100%;
  margin: auto;
  padding-top: 1rem;
}
#sidebar .sidebarUnlockUpselling .searchBox .btn {
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  width: 100%;
}
#sidebar .sidebarUnlockUpselling .resumeRow {
  min-width: 100%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 0.85rem;
  font-size: 0.9rem;
}
#sidebar .sidebarUnlockUpselling .resumeRow:first-child {
  padding-top: 2rem;
  border-top: 1px solid #fff;
  font-size: 1.1rem;
}
#sidebar .sidebarUnlockUpselling .resumeRow .creditCardUpselling {
  min-width: 100%;
  text-align: right;
  margin-top: 0.5rem;
}
#sidebar .creditCards {
  display: block;
  min-width: 100%;
  text-align: right;
  margin-top: -0.5rem;
}
#sidebar .custom-switch label {
  border: 1px solid #CCCCCC;
}
#sidebar .custom-switch input:not(:checked) ~ label {
  background: #fff;
}
#sidebar .custom-switch input:not(:checked) ~ label:after {
  background: #B3B3B3;
}
#sidebar .custom-switch .switch-off svg * {
  fill: #333333;
}
#sidebar .sidebarUnlockDestinations {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 13px;
}
#sidebar .sidebarUnlockDestinations .info {
  padding-top: 0;
  line-height: 120%;
}
#sidebar .sidebarUnlockDestinations svg {
  max-width: 14px;
  margin-right: 6px;
}
#sidebar .sidebarUnlockDestinations svg * {
  fill: #fff;
}
@media (min-width: 992px) {
#sidebar .sidebarUnlockDestinations {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    margin-bottom: 100px;
}
#sidebar .sidebarUnlockDestinations .info {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 140px;
    padding-right: 6px;
}
}
#sidebar .sidebar-boxsets, #sidebar .sidebarUnlockUpselling {
  padding-left: 30px;
  overflow: hidden;
  padding-bottom: 10px;
}
@media (min-width: 1600px) {
#sidebar .sidebar-boxsets, #sidebar .sidebarUnlockUpselling {
    padding-left: 60px;
}
}
#sidebar ul.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 0;
  border: 0;
}
#sidebar .nav-link {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: #333333;
  padding: 18px 22px 22px 20px;
  margin: 0;
}
@media (min-width: 1600px) {
#sidebar .nav-link {
    padding: 22px 26px 26px 22px;
}
}
#sidebar .nav-link.active {
  background-color: #fff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  overflow: hidden;
}
#sidebar .nav-link.active .nav-boxset-heading {
  margin-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#sidebar .nav-link.active .nav-boxset-heading .right {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem 0.25rem;
}
#sidebar .nav-link.active .nav-boxset-heading .resCopy {
  font-size: 11px;
}
#sidebar .nav-link.active .nav-boxset-info {
  opacity: 1;
}
@media (max-width: 1599.98px) {
#sidebar .nav-link.active .nav-boxset-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#sidebar .nav-link.active .nav-boxset-info > div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 20px;
}
}
@media (min-width: 1200px) {
#sidebar .nav-link.active .nav-boxset-info img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 20px;
}
}
@media (max-width: 1599.98px) {
#sidebar .nav-link.active .nav-boxset-info img {
    width: 60%;
}
}
#sidebar .nav-link.active .has-bell svg * {
  fill: #333333;
}
#sidebar .nav-link:not(.active) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  overflow: hidden;
  -webkit-box-shadow: 5.25076px 5.25076px 3.75054px rgba(0, 0, 0, 0.749);
          box-shadow: 5.25076px 5.25076px 3.75054px rgba(0, 0, 0, 0.749);
  padding-bottom: 15px;
  padding-top: 30px;
}
#sidebar .nav-link:not(.active) .pill-label,
#sidebar .nav-link:not(.active) .nav-boxset-name,
#sidebar .nav-link:not(.active) .nav-boxset-price {
  color: #fff;
  border-color: #fff;
}
#sidebar .nav-link:not(.active) .nav-boxset-info {
  opacity: 0;
}
#sidebar .nav-boxset-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#sidebar .nav-boxset-name {
  color: #333333;
  font-size: 14px;
}
@media (min-width: 1200px) {
#sidebar .nav-boxset-name {
    font-size: 16px;
}
}
@media (min-width: 1600px) {
#sidebar .nav-boxset-name {
    font-size: 20px;
}
}
#sidebar .boxsetIcon {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
@media (min-width: 1200px) {
#sidebar .boxsetIcon {
    min-width: 22px;
    width: 22px;
    height: 22px;
    margin-right: 8px;
}
}
@media (min-width: 1600px) {
#sidebar .boxsetIcon {
    min-width: 36px;
    width: 36px;
    height: 36px;
    margin-right: 10px;
}
}
#sidebar .nav-tabs .btn {
  font-weight: normal;
  font-size: 18px;
  padding-top: 0.45rem;
  padding-bottom: 0.5rem;
}
#sidebar .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#sidebar .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#sidebar .nav-boxset-name {
  margin-bottom: 0;
}
#sidebar .nav-boxset-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#sidebar .nav-boxset-thumb {
  width: 200px;
  height: auto;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}
@media (min-width: 1600px) {
#sidebar .nav-boxset-content {
    width: calc(100% - 130px);
    padding-left: 20px;
    font-size: 18px;
    max-width: 55%;
    min-width: 55%;
}
}
@media (max-width: 1599.98px) {
#sidebar .nav-boxset-content .left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
#sidebar .pill-label {
  font-size: 11px;
  margin-left: 6px;
}
#sidebar .has-bell {
  position: relative;
  margin-right: 10px;
}
#sidebar .has-bell svg {
  min-width: 25px;
  width: 25px;
  height: 23px;
}
#sidebar .has-bell svg path {
  fill: #fff;
}
#sidebar .has-bell .pill-number {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-40%) translateX(40%);
          transform: translateY(-40%) translateX(40%);
}
#sidebar .nav-boxset-price {
  text-align: right;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  border-left: 1px solid #333333;
  margin-left: 6px;
  padding-left: 8px;
}
@media (min-width: 1200px) {
#sidebar .nav-boxset-price {
    margin-left: 14px;
    padding-left: 14px;
    font-size: 14px;
}
}
@media (min-width: 1600px) {
#sidebar .nav-boxset-price {
    width: 65px;
    margin-left: 18px;
    padding-left: 18px;
    font-size: 16px;
}
}
#sidebar .nav-boxset-credit * {
  font-size: 18px;
  font-weight: bold;
}
#sidebar .custom-control-label {
  background: #fff;
  line-height: 140%;
}
#sidebar .custom-control-label:before:not(:checked) {
  border: 2px solid #CCCCCC;
  background: #fff;
}
#sidebar input[type=radio]:checked + label:before,
#sidebar .custom-radio.active label:before {
  border-color: #0077CA;
}
#sidebar input[type=radio]:checked + label:after,
#sidebar .custom-radio.active label:after {
  background: #0077CA;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
#sidebar #sidbar-info-box {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 60px 0 30px 80px;
}
#sidebar .noResults {
  font-size: 13px;
  margin-top: 1rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
#sidebar {
    /*
    @include hack-ie11
    {
      body
      {
        border:20px solid magenta !important;
      }
    }
    */
}
#sidebar .nav-boxset-info .boxsetThumbnail {
    width: 130px;
    max-width: 100%;
}
#sidebar .nav-boxset-heading {
    display: block;
}
#sidebar .nav-boxset-heading .left {
    float: left;
}
#sidebar .nav-boxset-heading .right {
    float: right;
}
#sidebar .nav-boxset-content .btn {
    display: inline-block;
}
}
@media (max-width: 991.98px) {
#sidebar.sidebarSearch {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    position: absolute;
    top: 178px;
    height: 54px;
    overflow: hidden;
    left: 0;
    right: 0;
}
#sidebar.sidebarSearch.loading, #sidebar.sidebarSearch.noResults {
    display: none;
}
#sidebar.sidebarSearch .sidebar-logo,
#sidebar.sidebarSearch #sidbar-info-box,
#sidebar.sidebarSearch .boxsetThumbnail,
#sidebar.sidebarSearch .nav-boxset-name,
#sidebar.sidebarSearch .btn,
#sidebar.sidebarSearch .right .nav-boxset-price,
#sidebar.sidebarSearch .right .resCopy,
#sidebar.sidebarSearch .creditKoCopy {
    display: none !important;
}
#sidebar.sidebarSearch .tabs {
    max-width: 100%;
}
#sidebar.sidebarSearch .sidebar-inner {
    height: 100%;
}
#sidebar.sidebarSearch .sidebar-boxsets, #sidebar.sidebarSearch .sidebarUnlockUpselling {
    padding: 0;
}
#sidebar.sidebarSearch .nav-link.active,
#sidebar.sidebarSearch .nav-link:not(.active) {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}
#sidebar.sidebarSearch .nav-link.active {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
}
#sidebar.sidebarSearch .nav-boxset {
    height: 51px;
    width: 50px;
    display: table-cell;
    vertical-align: middle;
}
#sidebar.sidebarSearch .nav-boxset-heading {
    width: 100%;
    display: block !important;
    text-align: center;
}
#sidebar.sidebarSearch .nav-boxset-heading svg {
    margin: 0;
    width: 70%;
    height: auto;
}
#sidebar.sidebarSearch .left {
    display: block;
    text-align: center;
}
#sidebar.sidebarSearch .nav-link {
    position: relative;
}
#sidebar.sidebarSearch .right {
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
#sidebar.sidebarSearch .right svg {
    display: none;
}
#sidebar.sidebarSearch .right .d-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#sidebar.sidebarSearch .right .has-bell {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 0;
}
#sidebar.sidebarSearch .right .pill-number {
    -webkit-transform: none;
            transform: none;
    right: 6px;
    bottom: 4px;
    top: auto;
    font-size: 8px;
}
#sidebar.sidebarSearch .nav-boxset-content {
    width: 100%;
    padding: 0;
}
#sidebar.sidebarSearch ul.nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
#sidebar .sso .nav-link:not(.active) .creditKoCopy {
  color: #fff !important;
  margin-top: 10px;
}
#sidebar .sso .nav-link:not(.active) .creditKoCopy svg path {
  fill: #fff;
}
#sidebar .sso .creditKo .has-bell svg path {
  fill: #B3B3B3;
}
#sidebar .sso .creditKo .pill-number {
  background-color: #B3B3B3;
  color: #fff !important;
}
#sidebar .sso .creditKo .nav-boxset-price {
  border-color: #B3B3B3;
}
#sidebar .sso .creditKoCopy {
  font-style: italic;
  width: 100%;
  min-width: 100%;
  display: block;
  text-align: center;
  font-size: 11px;
  font-weight: normal;
  text-align: right;
}
#sidebar .sso .creditKoCopy svg {
  width: 14px;
}
#sidebar .sso .creditKoCopy.visible {
  text-align: center;
  color: #333333;
  margin-top: 0.5rem;
}
@media (max-width: 991.98px) {
#sidebar .sidebarUnlockUpselling,
#sidebar .sidebarUnlockDestinations,
#sidebar .creditCards {
    display: none;
}
}
#sidebar .nightlyPriceBox input::-webkit-outer-spin-button,
#sidebar .nightlyPriceBox input::-webkit-inner-spin-button,
#sidebar .nightlyPriceBox input[type=number] {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}
#sidebar .checkboxUpselling {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#sidebar .checkboxUpselling label {
  padding-left: 12px;
  cursor: pointer;
  position: relative;
}
#sidebar .checkboxUpselling label a {
  text-decoration: underline;
  color: #fff;
  font-weight: 600;
}
#sidebar .checkboxUpselling label:before, #sidebar .checkboxUpselling label:after {
  content: "";
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 3px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
#sidebar .checkboxUpselling label:before {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
}
#sidebar .checkboxUpselling label:after {
  width: 6px;
  height: 6px;
  background: transparent;
  left: -2px;
  top: 5px;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#sidebar .checkboxUpselling input {
  height: auto !important;
  margin-top: 3px;
  opacity: 0;
}
#sidebar .checkboxUpselling input:checked ~ label:after {
  background: #fff;
}