.noResultsHelp {
  margin: 2rem 4rem;
}
.noResultsHelp .iconHelp {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-30%) translateX(30%);
          transform: translateY(-30%) translateX(30%);
  max-width: 100px;
}
.noResultsHelp .iconSmall {
  width: 14px;
  height: auto;
}
.noResultsHelp .iconSmall * {
  fill: #333333;
}
.noResultsHelp .help {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 0 0.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.noResultsHelp .help .title {
  color: #333333;
  font-weight: normal;
  font-size: 1rem;
  min-height: 2.5rem;
}
@media (min-width: 992px) {
.noResultsHelp .help {
    width: 25%;
}
}
.noResultsHelp .help span {
  display: inline-block;
  padding-left: 0.35rem;
  font-size: 14px;
  line-height: 130%;
  padding-right: 1rem;
  font-size: 0.75rem;
}
.noResultsHelp .help span a {
  color: #333333;
}
.noResultsHelp .help svg {
  width: 32px;
  height: 32px;
  max-width: 90%;
  margin-bottom: 1rem;
}
.noResultsHelp .help svg * {
  fill: #333333;
}
.noResultsHelp .noResultsHelpInner {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}