.noResults .searchSuggestionInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.noResults .noResultsTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  margin-top: 30px;
}
.noResults .noResultsTop .noResultsIcon {
  max-width: 20%;
  padding-right: 2rem;
}
.noResults .noResultsTop .noResultsIcon svg {
  width: 100%;
}
.noResults .noResultsTop strong {
  font-size: 18px;
}
.noResults .noResultsTop ul {
  margin-top: 1rem;
}
.noResults .noResultsTop ul li {
  position: relative;
  padding-left: 0.75rem;
  font-size: 14px;
  margin-bottom: 3px;
}
.noResults .noResultsTop ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  left: 0;
  background: #333333;
}
.noResults .flex-column a {
  min-width: 100%;
}
.noResults .btn-block {
  max-width: 300px;
  margin: auto;
}