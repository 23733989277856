.searchSuggestion {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.searchSuggestion .searchSuggestionInner {
  height: 100%;
}
.searchSuggestion .left {
  min-height: 200px;
  background-size: cover;
  background-position: center center;
}
.searchSuggestion .right {
  color: #333333;
  background: #fff;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.searchSuggestion p {
  font-size: 1.15rem;
}
.searchSuggestion button {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: 1rem;
  display: block;
  width: 100%;
  max-width: 200px;
}
@media (min-width: 992px) {
.searchSuggestion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.searchSuggestion .left, .searchSuggestion .right {
    min-height: 280px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    max-width: 50%;
}
}