.searchBoxsetAlert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 100%;
}
.searchBoxsetAlert .top {
  background: #f07b5d;
  width: 100%;
  color: #fff;
  padding: 1.5rem 1.25rem;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.searchBoxsetAlert .bottom {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 1rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.searchBoxsetAlert .bottom p {
  font-size: 14px;
}
.searchBoxsetAlert .bottom .boxsetResume {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.searchBoxsetAlert .bottom .boxsetResume .boxsetName, .searchBoxsetAlert .bottom .boxsetResume .salaBadge {
  font-size: 16px;
}
.searchBoxsetAlert .bottom button {
  margin-top: 2rem;
  margin-bottom: 0;
}
.searchBoxsetAlert .bottom .torna-indietro {
  margin-bottom: 0.5rem !important;
}