#search .bookingEngineSecondRow.desktop {
  display: none !important;
}
#search .card-header {
  display: none !important;
}
#search .card {
  border: 0;
  border-radius: 0;
  background: transparent;
}
#search .card-body {
  padding: 0;
}
#search .map-link {
  cursor: pointer;
}
#search .map-link svg {
  width: 100%;
  height: auto;
}
#search .map-link img {
  display: block;
  width: 100%;
  border-radius: 15px 15px 0 0;
  padding-bottom: 7px;
}
#search .map-link span {
  color: #0077CA;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  display: block;
  padding: 4px 6px;
  background-color: #fff;
  position: relative;
  top: -8px;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
}
#search #main.noResults #page {
  margin-top: 0;
}
#search .searchEmpty {
  margin: 0 auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 200px;
}
@media (min-width: 992px) {
#search #mobileBoxsetFilter {
    display: none;
}
#search #sidebar {
    display: block;
}
}
#search .noResults .results-info p:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
#search .card-text {
    margin-bottom: 0;
}
#search .cta__load-more {
    margin-top: 0;
    margin-bottom: 1rem;
}
}
@media (min-width: 992px) {
#app.search #appHeader {
    display: none;
}
}