.active-filters {
  margin-bottom: 2rem;
}
.active-filters .geo {
  font-weight: 300;
  margin-right: 6px;
}
.active-filters .badge {
  margin-right: 8px;
  font-weight: 500;
  padding: 4px 8px;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  padding-right: 24px;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  border-radius: 10px;
}
.active-filters .badge:hover {
  opacity: 0.75;
}
.active-filters .remove-filter {
  background: rgba(255, 255, 255, 0.35);
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(2px);
          transform: translateY(-50%) translateX(2px);
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 10px;
  font-weight: 300;
  line-height: 0;
}