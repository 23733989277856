#results {
  /*
  .resultsColumn
  {
      @include media-breakpoint-up(sm)
      {

      }
      @include media-breakpoint-up(md)
      {

      }
      @include media-breakpoint-up(lg)
      {
          padding-left:$grid-gutter-width/2;
          padding-right:$grid-gutter-width/2;
      }
  }
  */
}
#results div[class|=boxset] > strong span {
  margin-right: 1rem;
}
#results .load-more {
  margin: 0.5rem 0;
}
#results .searchSuggestion {
  margin: 0;
  padding-bottom: 30px;
  height: 100%;
}
#results .searchSuggestion .searchSuggestionInner {
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
#results .searchSuggestion .left {
  height: 200px;
  min-height: 200px;
}
#results .searchSuggestion .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#results .searchSuggestion .left, #results .searchSuggestion .right {
  max-width: 100%;
}
#results .searchSuggestion p strong {
  white-space: initial;
}
#results .results-info {
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
#results .results-info strong {
    font-size: 18px;
}
}
@media (max-width: 991.98px) {
#results .results-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    line-height: 100%;
}
#results .results-info > div:first-child {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
#results .results-info > div:last-child {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
#results .results-info small {
    display: block;
}
#results .results-info small, #results .results-info small * {
    font-size: 14px;
    margin-top: 0.5rem;
}
#results .results-info a {
    display: none;
}
}
@media (max-width: 1199.98px) {
#results .results-info p {
    font-size: 14px;
    line-height: 100%;
    padding-right: 1rem;
    margin-top: 20px;
}
}
#results .pageTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}
#results .pageTop .boxsetThumbnail {
  width: 140px;
  /*
  height:80px;
  min-width:80px;
  min-height:80px;
  */
}
#results .pageTop .mobileResume {
  padding-left: 1rem;
  text-align: center;
}
#results .pageTop .btn {
  font-size: 11px;
  margin: auto;
  max-width: 80%;
}
#results .pageTop .boxsetResume {
  margin-bottom: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 1rem;
}
#results .pageTop .boxsetResume svg {
  /*
  width:16px;
  height:16px;
  transform:none;
  */
  display: none;
}
#results .pageTop .boxsetResume .boxsetName {
  font-size: 21px;
}
#results .pageTop .boxsetResume .salaBadge {
  font-size: 11px;
  padding: 3px 8px;
}
#results .pageTop .boxsetResume strong {
  font-size: 11px;
}
#results .pageTop .boxsetResume > * {
  margin-right: 4px;
}
#results .pageTop .creditKoCopy {
  display: block;
  min-width: 100%;
  margin-top: 0.5rem;
  font-size: 11px;
  margin-bottom: 0;
}
#results .pageTop .creditKoCopy svg {
  width: 14px;
}
@media (min-width: 992px) {
#results .pageTop {
    display: none;
}
}
@media (min-width: 576px) {
.resultsColumn .property__inner {
    margin-bottom: 40px;
}
}